import React from "react";
import {
  types,
  flow,
  getSnapshot,
  getRoot,
  applySnapshot,
  unprotect,
  protect
} from "mobx-state-tree";

import Http from "@lib/http";
import { displayForceDeleteMessage } from "@components/askForDelete";

export const ExtrafieldItem = types.model("ExtrafieldItem", {
  id: types.number,
  name: types.string
});

export const Extrafield = types
  .model("Extrafield", {
    id: types.identifierNumber,
    name: types.string,
    slug: types.string,
    required: types.boolean,
    enabled: types.boolean,
    type: types.enumeration(["choice", "text", "multiple-choice"]),
    items: types.array(ExtrafieldItem)
  })
  .actions(self => ({
    toggle: flow(function* toggleExtrafield() {
      self.enabled = !self.enabled;
      yield Http.update(`/extrafield/${self.id}`, {
        ...getSnapshot(self),
        enabled: self.enabled
      });
    }),

    update: flow(function* updateExtrafield(values) {
      const root = getRoot(self);
      unprotect(root);
      root.loading = true;

      try {
        if (values.items) {
          values.items = values.items
            .map((value, idx) => {
              const oldItem = self.items[idx];
              if (oldItem)
                return {
                  ...oldItem,
                  name: value
                };

              return {
                name: value
              };
            })
            .filter(Boolean);
        }

        const response = yield Http.update(`/extrafield/${self.id}`, values);
        applySnapshot(self, response.data);
      } finally {
        root.loading = false;
        protect(root);
      }
    }),

    delete: flow(function* deleteExtrafield() {
      try {
        yield Http.delete(`/extrafield/${self.id}`);
        getRoot(self).deleteExtrafield(self);
      } catch (error) {
        if (error.response && error.response.data.reason === "ASK_FOR_DELETE") {
          displayForceDeleteMessage({
            url: `/extrafield/${self.id}`,
            description: (
              <>
                Impossible de supprimer le champ car il est utilisé.
                <br />
                <br />
                Si vous supprimez ce champ toutes les données de ce champ seront
                supprimées définitivement.
              </>
            ),
            deleteCallback: () => getRoot(self).deleteExtrafield(self)
          });
        } else throw error;
      }
    }),

    edit() {
      getRoot(self).editExtrafield(self);
    }
  }));
