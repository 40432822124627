import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Result } from "antd";

import ClientSelector from "./clientSelector";
import clientStore from "../models/store";

function ClientInit({ children, identifier }) {
  useEffect(() => {
    clientStore.getClient(identifier);
  }, [identifier]);

  const client = clientStore.client;

  if (clientStore.displaySelector) return <ClientSelector />;

  if (!client) return null; // TODO: Global app loader

  return clientStore.isForbidden ? (
    <div className="client-forbidden-container">
      <div className="client-forbidden">
        <Result
          status="error"
          title="Accès refusé"
          subTitle={
            <span>
              Le client <b>{client.name}</b> est désactivé.
            </span>
          }
        />
      </div>
    </div>
  ) : (
    children
  );
}

export default observer(ClientInit);
