import React from "react";
import { Link } from "react-navi";
import { Result, Button } from "antd";

export default function NotFound() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Désolé, la page n'existe pas."
      extra={
        <Button type="primary">
          <Link href="/">Accueil</Link>
        </Button>
      }
    />
  );
}
