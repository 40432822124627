import React from "react";
import { Result } from "antd";

export default class GlobalErrorBoundary extends React.Component {
  state = {
    error: null,
    errorInfo: null
  };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
    this.setState({
      error,
      errorInfo
    });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="component-error">
          <Result status="500" title="Une erreur est survenue">
            {this.state.errorInfo && (
              <div className="error-stack">
                {this.state.error && this.state.error.toString()}
                <br />
                {this.state.errorInfo.componentStack}
              </div>
            )}
          </Result>
        </div>
      );
    }

    return this.props.children;
  }
}
