import {
  types,
  flow,
  getRoot,
  destroy,
  unprotect,
  protect
} from "mobx-state-tree";

import Http from "@lib/http";

export const List = types
  .model("List", {
    id: types.number,
    name: types.string,
    listGroupId: types.number,
    children: types.maybe(types.array(types.late(() => List)))
  })
  .actions(self => ({
    afterCreate() {
      if (self.children && self.children.length === 0) {
        self.children = undefined;
      }
    },

    addChild(list) {
      if (!self.children) {
        self.children = [];
      }

      self.children.push(list);
    },

    update: flow(function* updateList(name) {
      yield Http.update(`/list/${self.id}`, { name });
      self.name = name;
    }),

    delete() {
      getRoot(self).deleteList(self);
    },

    remove() {
      const root = getRoot(self);
      unprotect(root);
      destroy(self);
      protect(root);
    }
  }));
