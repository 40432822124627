/* eslint-disable require-yield */
import React from "react";
import { types, flow, destroy, unprotect, protect } from "mobx-state-tree";
import sortBy from "lodash/sortBy";
import omit from "lodash/omit";

import Http from "@lib/http";
import { displayForceDeleteMessage } from "@components/askForDelete";

import { List } from "./list";
import { Group } from "./group";
import clientStore from "../../../client/models/store";

const HierarchyStore = types
  .model("HierarchyStore", {
    fetchedLists: false,
    fetchedGroups: false,
    lists: types.array(List),
    groups: types.array(Group)
  })
  .views(self => ({
    get orderedGroups() {
      return sortBy(self.groups, ["level"]);
    },

    get flattened() {
      const results = [];

      function traverse(list) {
        if (!list.children) {
          return;
        }

        for (const child of list.children) {
          results.push({ parentId: list.id, ...omit(child, ["children"]) });
          traverse(child);
        }
      }

      for (const list of self.lists) {
        results.push(omit(list, ["children"]));
        traverse(list);
      }

      return results;
    }
  }))
  .actions(self => ({
    fetchGroups: flow(function* fetchGroups() {
      if (self.fetchedGroups) {
        return;
      }

      const response = yield Http.get("/client/lists/groups");
      self.groups = response.data;
      self.fetchedGroups = true;
    }),

    fetchLists: flow(function* fetchLists() {
      if (self.fetchedLists) {
        return;
      }

      const response = yield Http.get("/client/lists");
      self.lists = response.data;
      self.fetchedLists = true;
    }),

    addNewGroup: flow(function* addNewGroup(label) {
      const response = yield Http.post(
        `/client/${clientStore.client.id}/group`,
        { label }
      );
      self.groups.push(response.data);
    }),

    deleteGroup: flow(function* deleteGroup(group) {
      const nextGroups = self.groups.filter(g => g.level > group.level);
      const startIndex = self.groups.findIndex(g => g.id === group.id);

      function doDelete() {
        function recursiveDelete(cpt, list) {
          if (cpt === startIndex) {
            list.children = [];
            return;
          }

          for (const child of list.children) {
            recursiveDelete(cpt + 1, child);
          }
        }

        if (startIndex === 0) {
          self.lists = [];
        } else {
          for (const list of self.lists) {
            recursiveDelete(1, list);
          }
        }

        destroy(group);
        nextGroups.forEach(nextGroup => destroy(nextGroup));
      }

      try {
        yield Http.delete(`/group/${group.id}`);
        doDelete();
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.reason === "ASK_FOR_DELETE"
        ) {
          displayForceDeleteMessage({
            url: `/group/${group.id}`,
            description: (
              <>
                Impossible de supprimer le niveau car il est utilisé.
                <br />
                <br />
                Si vous supprimez ce niveau toute la partie de l'arborescence
                correspondante sera supprimée.
              </>
            ),
            deleteCallback: () => {
              unprotect(self);
              doDelete();
              protect(self);
            }
          });
        } else throw error;
      }
    }),

    addList: flow(function* addList(list, parent) {
      const response = yield Http.post(
        `/client/${clientStore.client.id}/list`,
        list
      );
      if (parent) {
        parent.addChild(response.data);
      } else {
        self.lists.push(response.data);
      }
    }),

    deleteList: flow(function* deleteList(list) {
      try {
        yield Http.delete(`/list/${list.id}`);
        destroy(list);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.reason === "ASK_FOR_DELETE"
        ) {
          displayForceDeleteMessage({
            url: `/list/${list.id}`,
            description: (
              <>
                Impossible de supprimer cet élément car il est utilisé.
                <br />
                <br />
                Si vous supprimez cet élément toute la partie de l'arborescence
                liée à cet élement sera également supprimée.
              </>
            ),
            deleteCallback: list.remove
          });
        } else throw error;
      }
    })
  }));

export default HierarchyStore.create();
