import React from "react";
import { Cascader } from "antd";
import { observer } from "mobx-react";

import hierarchyStore from "../models/store";

function ListSelector({ onChange, value, ...props }, ref) {
  // eslint-disable-next-line no-unused-vars
  const length = hierarchyStore.lists.length; // pour trigger une update

  return (
    <Cascader
      ref={ref}
      options={hierarchyStore.lists}
      placeholder="Sélectionner une partie de l'arborescence"
      fieldNames={{
        children: "children",
        value: "id",
        label: "name"
      }}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
}

export default observer(React.forwardRef(ListSelector));
