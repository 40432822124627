import React from "react";
import { Form, Input, Select } from "antd";

const Option = Select.Option;
const getPopupContainer = () => document.querySelector(".ant-drawer-body");

export default function ExtraFieldForm({
  field,
  mode,
  user,
  getFieldDecorator,
}) {
  let formItem = null;

  const opt = {
    rules: [
      {
        required: field.required,
        message: "Ce champ est obligatoire",
      },
    ],
  };

  switch (field.type) {
    case "text":
      if (mode === "edit") {
        const ue = user.userExtrafields.find(
          (ue) => ue.extrafieldId === field.id
        );
        if (ue) {
          opt.initialValue = ue.value;
        }
      }
      formItem = getFieldDecorator(`extrafields.${field.id}`, opt)(<Input />);

      break;
    case "choice":
      if (mode === "edit") {
        const ue = user.userExtrafields.find(
          (ue) => ue.extrafieldId === field.id
        );
        if (ue) {
          opt.initialValue = ue.itemId;
        }
      }

      formItem = getFieldDecorator(
        `extrafields.${field.id}`,
        opt
      )(
        <Select allowClear getPopupContainer={getPopupContainer}>
          {field.items.map((i) => (
            <Option key={`item.${i.id}`} value={i.id}>
              {i.name}
            </Option>
          ))}
        </Select>
      );
      break;

    case "multiple-choice":
      if (mode === "edit") {
        const ues = user.userExtrafields.filter(
          (ue) => ue.extrafieldId === field.id
        );
        if (ues.length > 0) {
          opt.initialValue = ues.map((ue) => ue.itemId);
        }
      }

      formItem = getFieldDecorator(
        `extrafields.${field.id}`,
        opt
      )(
        <Select mode="multiple" getPopupContainer={getPopupContainer}>
          {field.items.map((i) => (
            <Option key={`item.${i.id}`} value={i.id}>
              {i.name}
            </Option>
          ))}
        </Select>
      );
      break;

    default:
      formItem = null;
  }

  return <Form.Item label={field.name}>{formItem}</Form.Item>;
}
