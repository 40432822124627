export const USERNAME_PASSWORD = "USERNAME_PASSWORD";

export const COMPLETED = "COMPLETED";
export const INCOMPLETE = "INCOMPLETE";
export const UNKNOWN = "UNKNOWN";

export const progressToString = {
  COMPLETED: "Terminé",
  INCOMPLETE: "Commencé",
  UNKNOWN: "Inscrits",
};

export const LEARNER = "LEARNER";
export const ADMIN = "ADMIN";
export const TUTOR = "TUTOR";
export const SALES = "SALES";
export const SUPER_ADMIN = "SUPER_ADMIN";

export const rolesToString = {
  ADMIN: "Admin",
  LEARNER: "Apprenant",
  TUTOR: "Tuteur",
  SALES: "Commercial",
  SUPER_ADMIN: "Captain 2J",
};

export const DEFAULT_PAGE_SIZE = 10;
