import React, { Suspense } from "react";
import { View, useLoadingRoute } from "react-navi";
import { Spin } from "antd";

import AppHeader from "../app-header";
import AppFooter from "../app-footer";
import AppMenu from "../app-menu";
import LoadingBar from "../loadingBar";

export default function BaseLayout() {
  const loadingRoute = useLoadingRoute();

  return (
    <>
      {!!loadingRoute && <LoadingBar />}
      <AppHeader />
      <div className="main-wrapper">
        <AppMenu />
        <div className="main-content">
          <div className="main-content-inner">
            <Suspense
              fallback={
                <Spin
                  style={{ margin: "2rem auto 0", display: "block" }}
                  delay={500}
                  size="large"
                  tip="Chargement..."
                />
              }
            >
              <View />
            </Suspense>
          </div>

          <AppFooter />
        </div>
      </div>
    </>
  );
}
