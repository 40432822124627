import { types, flow, getRoot } from "mobx-state-tree";

import Http from "@lib/http";

export const Group = types
  .model("ListGroup", {
    id: types.number,
    label: types.string,
    level: types.number,
    slug: types.string
  })
  .actions(self => ({
    update: flow(function* updateGroup(label) {
      self.label = label;

      yield Http.update(`/group/${self.id}`, { label });
    }),

    delete() {
      getRoot(self).deleteGroup(self);
    }
  }));
