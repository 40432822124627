import Cookie from "js-cookie";

export const defaultConfig = {
  baseURL: process.env.REACT_APP_API_URL,
};

export const AT_COOKIE_NAME = "2j-mobile-AT";
export const RT_COOKIE_NAME = "2j-mobile-RT";

function setCookie(name, value) {
  if (!value) {
    Cookie.remove(name);
  } else {
    Cookie.set(name, value, {
      secure: process.env.NODE_ENV === "production",
    });
  }
}

export const tokens = {
  accessToken: undefined,
  refreshToken: undefined,

  get AT() {
    return this.accessToken;
  },

  set AT(token) {
    this.accessToken = token;

    setCookie(AT_COOKIE_NAME, token);
  },

  get RT() {
    return this.refreshToken;
  },

  set RT(token) {
    this.refreshToken = token;

    setCookie(RT_COOKIE_NAME, token);
  },
};
