import React from "react";
import { Modal, Button, Form, Spin } from "antd";
import flatMap from "lodash/flatMap";

import clientStore from "@features/client/models/store";
import authService from "@lib/auth/authService";
import ExtraFieldForm from "@features/users/components/forms/extrafieldForm";
import extrafieldStore from "@features/settings/extrafields/models/store";
import hierarchyStore from "@features/settings/lists/models/store";
import ListSelector from "@features/settings/lists/components/selector";
import Http from "@lib/http";

class ModalDoubleAuth extends React.Component {
  state = { loading: true, visible: true };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (values.extrafields) {
        values.extrafields = flatMap(
          Object.entries(values.extrafields),
          ([id, values]) => {
            if (Array.isArray(values)) {
              return values.map((value) => ({
                extrafieldId: +id.replace("id_", ""),
                value,
              }));
            } else
              return {
                extrafieldId: +id.replace("id_", ""),
                value: values,
              };
          }
        ).filter((field) => field.value !== undefined);

        if (values.extrafields.length === 0) delete values.extrafields;
      }

      await Http.post(`/user/complete`, {
        id: authService.currentUser.id,
        listIds: values.listIds,
        extrafields: values.extrafields,
      });
      await authService.refreshUser();
      this.setState({
        visible: false,
        loading: false,
      });
    });
  };

  async componentDidMount() {
    await Promise.all([
      hierarchyStore.fetchLists(),
      extrafieldStore.fetchExtrafields(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <Modal
        closable={false}
        title="Informations complémentaire"
        visible={this.state.visible}
        footer={null}
      >
        {this.state.loading ? (
          <div className="loader">
            <Spin size="large" />
          </div>
        ) : (
          <Form onSubmit={this.handleSubmit} style={{ paddingBottom: 108 }}>
            {clientStore.client.preferences.withLists &&
              authService.currentUser.lists < 1 && (
                <Form.Item label="Arborescence">
                  {getFieldDecorator("listIds", {
                    initialValue: undefined,
                    rules: [
                      {
                        required: true,
                        message: "Veuillez sélectionner un élément",
                      },
                    ],
                  })(<ListSelector changeOnSelect placeholder="" />)}
                </Form.Item>
              )}

            {clientStore.client.preferences.withExtrafields &&
              extrafieldStore.requiredFields.map(
                (field) =>
                  !authService.currentUser.userExtrafields.find(
                    (ue) => ue.extrafieldId === field.id
                  ) && (
                    <ExtraFieldForm
                      key={field.name}
                      {...{
                        field,
                        mode: "complete",
                        user: authService.currentUser,
                        getFieldDecorator,
                      }}
                    />
                  )
              )}
            <Button
              htmlType="submit"
              type="primary"
              loading={this.state.loading}
              style={{ position: "absolute", bottom: "1rem", right: "1rem" }}
            >
              Valider
            </Button>
          </Form>
        )}
      </Modal>
    );
  }
}

export default Form.create()(ModalDoubleAuth);
