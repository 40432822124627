import { types, flow, destroy } from "mobx-state-tree";

import Http from "@lib/http";
import { Extrafield } from "./extrafield";

const ExtrafieldStore = types
  .model("ExtrafieldStore", {
    fetched: false,
    loading: false,
    editing: false,
    field: types.safeReference(Extrafield),
    extrafields: types.array(Extrafield)
  })
  .views(self => ({
    get enabledFields() {
      return self.extrafields.filter(field => field.enabled);
    },

    get choiceFields() {
      return self.enabledFields.filter(field =>
        ["choice", "multiple-choice"].includes(field.type)
      );
    },

    get requiredFields() {
      return self.enabledFields.filter(field => field.required);
    },

    getField(id) {
      return self.enabledFields.find(field => field.id === Number(id));
    }
  }))
  .actions(self => ({
    fetchExtrafields: flow(function* fetchExtrafields() {
      if (self.fetched) {
        return;
      }

      const response = yield Http.get("/client/extrafields");
      self.fetched = true;
      self.extrafields = response.data;
    }),

    addNewField: flow(function* newField(values, clientId) {
      if (self.loading) return;

      self.loading = true;
      try {
        const response = yield Http.post(
          `/client/${clientId}/extrafield`,
          values
        );

        self.extrafields.push(response.data);
      } finally {
        self.loading = false;
      }
    }),

    deleteExtrafield(field) {
      destroy(field);
    },

    editExtrafield(field) {
      self.field = field;
      self.editing = true;
    },

    cancelEdit() {
      self.editing = false;
    }
  }));

export default ExtrafieldStore.create({ extrafields: [] });
