import React, { Suspense } from "react";
import { View, Router, NotFoundBoundary } from "react-navi";
import HelmetProvider from "react-navi-helmet-async";

import authService from "@lib/auth/authService";
import Http from "@lib/http";
import ClientInit from "@features/client/components/index";
import getClientIdentifierFromURL from "@features/client/getClientIdentifier";

import { routes } from "./routes";
import NotFound from "./components/notFound";

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authService.currentUser,
    };

    this.unsubscribe = null;
    this.clientIdentifier = getClientIdentifierFromURL();
    if (this.clientIdentifier) {
      Http.setIdentifier(this.clientIdentifier);
    }
  }

  setCurrentUser = (user) => this.setState({ currentUser: user });

  componentDidMount() {
    this.unsubscribe = authService.subscribe(this.setCurrentUser);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const router = (
      <Router
        routes={routes}
        context={{ currentUser: this.state.currentUser, authService }}
      >
        <Suspense fallback={null}>
          <NotFoundBoundary render={NotFound}>
            <View />
          </NotFoundBoundary>
        </Suspense>
      </Router>
    );

    return (
      <HelmetProvider>
        {this.clientIdentifier ? (
          <ClientInit identifier={this.clientIdentifier}>{router}</ClientInit>
        ) : (
          router
        )}
      </HelmetProvider>
    );
  }
}
