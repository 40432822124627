import axios from "axios";
import { notification } from "antd";
import debounce from "lodash/debounce";

import Awaiter from "./awaiter";
import { defaultConfig, tokens } from "./config";

const SESSION_EXPIRED_STATUS_CODE = 440;
const CLIENT_ERROR = "CLIENT_ERROR";
const SERVER_ERROR = "SERVER_ERROR";
const TIMEOUT_ERROR = "TIMEOUT_ERROR";
const NETWORK_ERROR = "NETWORK_ERROR";
const UNKNOWN_ERROR = "UNKNOWN_ERROR";

export default class Http {
  static client = axios.create(defaultConfig);

  static init() {
    Http.client.interceptors.response.use(
      response => {
        if (response.headers["x-api-newtoken"]) {
          Http.setTokens({ AT: response.headers["x-api-newtoken"] });
        }

        return response;
      },
      async error => {
        if (
          error.response &&
          error.response.status === SESSION_EXPIRED_STATUS_CODE
        ) {
          const requestConfig = error.config;
          delete requestConfig.headers.Authorization;

          try {
            const awaiter = new Awaiter(() => Http.client(requestConfig));
            return await awaiter.handle();
          } catch (error) {}
        } else {
          const problem = getProblemFromError(error);
          notifyError(problem);
        }

        return Promise.reject(error);
      }
    );
  }

  static setIdentifier(clientIdentifier) {
    if (clientIdentifier) {
      Http.client.defaults.headers.common["x-api-clientid"] = clientIdentifier;
    }
  }

  static setTokens({ AT, RT }) {
    if (AT) {
      Http.client.defaults.headers.common["Authorization"] = `Bearer ${AT}`;

      tokens.AT = AT;
    }

    if (RT) {
      tokens.RT = RT;
    }
  }

  static clearTokens() {
    delete Http.client.defaults.headers.common["Authorization"];
    tokens.AT = undefined;
    tokens.RT = undefined;
  }

  static get(url, config = {}) {
    return Http.client({
      method: "GET",
      url,
      ...config
    });
  }

  static post(url, data, config = {}) {
    return Http.client({
      method: "POST",
      url,
      data,
      ...config
    });
  }

  static update(url, data, config = {}) {
    return Http.client({
      method: "PUT",
      url,
      data,
      ...config
    });
  }

  static delete(url, config = {}) {
    return Http.client({
      method: "DELETE",
      url,
      ...config
    });
  }
}

function getProblemFromError(error) {
  if (error.message === "Network Error") return NETWORK_ERROR;

  if (!error.code) {
    if (error.response && error.response.status)
      return getProblemFromStatus(error.response.status);
  } else if (error.code.includes("ECONNABORTED")) {
    return TIMEOUT_ERROR;
  }

  return UNKNOWN_ERROR;
}

function getProblemFromStatus(status) {
  if (status >= 400 && status <= 499) return CLIENT_ERROR;
  if (status >= 500 && status <= 599) return SERVER_ERROR;

  return UNKNOWN_ERROR;
}

const notifyError = debounce(problem => {
  if (problem === CLIENT_ERROR) return;

  switch (problem) {
    case SERVER_ERROR:
      notification.error({
        message: "Erreur!",
        description: "Une erreur est survenue sur le serveur."
      });
      break;
    case TIMEOUT_ERROR:
      notification.error({
        message: "Erreur!",
        description: "Le serveur est surchargé."
      });
      break;
    case NETWORK_ERROR:
      notification.error({
        message: "Erreur!",
        description:
          "Le serveur n'est pas joignable, veuillez vérifier si vous êtes connecté à internet."
      });
      break;
    default:
      notification.error({
        message: "Erreur!",
        description:
          "Une erreur non identifiée est survenue, veuillez consulter la console."
      });
      break;
  }
}, 750);
