import React from "react";
import { notification, Button } from "antd";

import Http from "@lib/http";
import delay from "@lib/delay";

class AskForDelete extends React.PureComponent {
  state = {
    loading: false
  };

  forceDelete = async () => {
    this.setState({ loading: true });

    if (this.props.url)
    await Http.delete(this.props.url, {
      params: {
        force: true
      }
    });
    else if (typeof this.props.httpCallback === "function")
    await this.props.httpCallback()

    await delay(750);
    this.safeSetState({ loading: false });
    setTimeout(() => {
      this.props.deleteCallback();
      notification.close("ask-for-delete");
    }, 250);
  };

  safeSetState = obj => {
    if (!this.mounted) return;

    this.setState(obj);
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    return (
      <div>
        <p>{this.props.description}</p>
        <Button
          loading={this.state.loading}
          type="danger"
          onClick={this.forceDelete}
        >
          Forcer la suppression
        </Button>
      </div>
    );
  }
}

export function displayForceDeleteMessage({
  url,
  description,
  deleteCallback,
  httpCallback
}) {
  notification.warning({
    message: "Attention!",
    key: "ask-for-delete",
    duration: null,
    description: (
      <AskForDelete
        url={url}
        httpCallback={httpCallback}
        description={description}
        deleteCallback={deleteCallback}
      />
    )
  });
}
