import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { ConfigProvider, message } from "antd";
import frFR from "antd/es/locale/fr_FR";
import moment from "moment";
import "moment/locale/fr";

import Http from "@lib/http";
import authService from "@lib/auth/authService";

import "./styles/index.less";
import App from "./app";
import GlobalErrorBoundary from "./components/globalErrorBoundary";

moment.locale("fr");

message.config({
  top: 64 - 20,
});

async function boot() {
  Http.init();
  await authService.init();

  ReactDOM.render(
    <GlobalErrorBoundary>
      <ConfigProvider locale={frFR}>
        <App />
      </ConfigProvider>
    </GlobalErrorBoundary>,
    document.getElementById("root")
  );
}

boot();
