import axios from "axios";
import { notification } from "antd";

import { defaultConfig, tokens } from "./config";
import Http from "./index";
import authService from "../auth/authService";

const REFRESH_SESSION_URL = "/auth/refresh";

export default class Awaiter {
  static lockedBy = null;
  static queue = new Set();
  static listeners = new Map();

  static clean() {
    Awaiter.queue = new Set();
    Awaiter.listeners = new Map();
    Awaiter.lockedBy = null;
  }

  constructor(request) {
    this.id = randomId();
    this.request = request;

    if (Awaiter.queue.size === 0) {
      Awaiter.lockedBy = this.id;
    }

    Awaiter.queue.add(this.id);
  }

  async handle() {
    if (Awaiter.lockedBy === this.id) {
      try {
        const axiosInstance = axios.create(defaultConfig);
        const {
          data: { newAccessToken, newRefreshToken }
        } = await axiosInstance.post(REFRESH_SESSION_URL, {
          refreshToken: tokens.RT
        });

        Http.setTokens({ AT: newAccessToken.value, RT: newRefreshToken.value });
      } catch (error) {
        authService.logout();

        Awaiter.clean();

        notification.warning({
          message: "Attention!",
          description: "Votre session a expiré, vous avez été déconnecté.",
          duration: 0,
          placement: "topLeft"
        });

        return;
      }

      const response = await this.request();
      Awaiter.queue.delete(this.id);
      Awaiter.lockedBy = undefined;
      Awaiter.listeners.forEach(fn => fn());

      return response;
    } else {
      return new Promise(resolve => {
        Awaiter.listeners.set(this.id, async () => {
          const response = await this.request();

          Awaiter.listeners.delete(this.id);
          Awaiter.queue.delete(this.id);

          resolve(response);
        });
      });
    }
  }
}

function generateRandomString(length) {
  const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
  let randomstring = "";
  for (let i = 0; i < length; i++) {
    const idx = Math.floor(Math.random() * chars.length);
    randomstring += chars[idx];
  }
  return randomstring;
}

function randomId() {
  return Date.now().toString() + generateRandomString(15);
}
