import React from "react";
import clientStore from "../../features/client/models/store";

export default function Logo() {
  return (
    <img
      className="logo"
      // src={require("../../styles/images/logo/logo.png")}
      src={clientStore.client.logoSrc}
      alt={clientStore.client.logoAlt}
    />
  );
}
