import React, { useState } from "react";
import { Input, Typography, Alert, Button, notification } from "antd";

import Http from "@lib/http";

export default function ClientSelector() {
  const [code, setCode] = useState("");

  async function checkCode() {
    if (code === "") {
      notification.error({
        message: "Erreur",
        description: "Vous devez renseigner un code."
      });

      return;
    }

    try {
      const response = await Http.get(`/client/${code}`);

      const url = new URL(window.location);
      const urlArray = url.host.split(".");
      urlArray.shift();
      urlArray.unshift(response.data.identifier);

      window.location.href = url.protocol + "//" + urlArray.join(".");
    } catch (error) {
      notification.error({
        message: "Erreur",
        description: "Code client incorrect."
      });
    }
  }

  return (
    <div className="client-selector-container">
      <div className="client-selector">
        <Alert
          message="Attention"
          description="L'URL ne correspond à aucun client, veuillez renseigner le code client qui vous a été fourni."
          type="warning"
          showIcon
        />
        <div className="client-selector-box">
          <Typography.Title level={4}>Code client</Typography.Title>
          <Input
            size="large"
            value={code}
            onChange={e => setCode(e.target.value)}
            onKeyUp={e => {
              if (e.key === "Enter") {
                checkCode();
              }
            }}
          />
          <Button type="primary" block onClick={checkCode}>
            Valider
          </Button>
        </div>
      </div>
    </div>
  );
}
