import React from "react";
import { Menu, Icon, Dropdown, Avatar } from "antd";
import { Link } from "react-navi";

import authService from "@lib/auth/authService";

export default function Profile() {
  const menu = (
    <Menu>
      <Menu.Item key="userinfo">
        <Link href="/users/profile">Mon profil</Link>
      </Menu.Item>

      <Menu.Divider />
      <Menu.Item key="logout" onClick={authService.logout}>
        <Icon type="logout" />
        <span>Se déconnecter</span>
      </Menu.Item>
    </Menu>
  );

  const currentUser = authService.currentUser;
  if (!currentUser) return null;

  return (
    <Dropdown overlay={menu}>
      <span className="profile">
        <Avatar icon="user" size="small" />
        <span className="profile-title">
          {currentUser.firstname} {currentUser.lastname}
        </span>
      </span>
    </Dropdown>
  );
}
