import React from "react";
import { Divider } from "antd";

export default function AppFooter() {
  return (
    <footer className="app-footer">
      <a href="/legal" target="_blank">
        Conditions générales d'utilisation
      </a>
      <Divider type="vertical" />
      <span>
        Une réalisation{" "}
        <a
          href="https://2jprocess.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          2J Process
        </a>
      </span>
    </footer>
  );
}
