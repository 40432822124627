import Cookie from "js-cookie";
import axios from "axios";

import Http from "../http";
import {
  defaultConfig,
  AT_COOKIE_NAME,
  RT_COOKIE_NAME,
  tokens,
} from "../http/config";

export const AuthMethods = {
  EmailCode: "EMAIL_CODE",
  UsernamePassword: "USERNAME_PASSWORD",
};

class AuthService {
  constructor() {
    this.user = null;
    this.callback = undefined;

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  async init() {
    try {
      // Hydrate un objet user si le cookie est présent
      const AT = Cookie.get(AT_COOKIE_NAME);
      const RT = Cookie.get(RT_COOKIE_NAME);
      if (AT && RT) {
        Http.setTokens({ AT, RT });

        const { data: user } = await Http.get("/user");
        this.currentUser = user;
      }
    } catch (error) {
      console.warn("INIT - authService");
    }
  }

  get currentUser() {
    return this.user;
  }

  set currentUser(user) {
    this.user = user;
    window.user = user;
  }

  subscribe(callback) {
    this.callback = callback;
    return () => {
      this.callback = undefined;
    };
  }

  async login(username, password) {
    const { data } = await Http.post(
      "/auth/login",
      {
        username,
        password,
        mode: AuthMethods.UsernamePassword,
      },
      {
        params: {
          noRenew: true,
        },
      }
    );

    Http.setTokens({
      AT: data.accessToken.value,
      RT: data.refreshToken.value,
    });

    const { data: user } = await Http.get("/user");
    this.currentUser = user;

    if (this.callback && typeof this.callback === "function") {
      this.callback(user);
    }
  }

  async logout() {
    try {
      // Nouvelle instance pour éviter les intercepteurs
      await axios.post("/auth/logout", null, {
        ...defaultConfig,
        headers: {
          Authorization: `Bearer ${tokens.AT}`,
        },
      });
    } catch (error) {
      // Dans tous les cas si le logout échoue on redirige sur le login
    }

    if (this.currentUser.clientId === 6) {
      delete_cookie(AT_COOKIE_NAME);
      delete_cookie(AT_COOKIE_NAME, "/", ".baudin.2jmobile.com");
      delete_cookie(RT_COOKIE_NAME);
      delete_cookie(RT_COOKIE_NAME, "/", ".baudin.2jmobile.com");

      let field = this.currentUser.userExtrafields.find(
        (f) => f.extrafieldId === 37
      );
      if (field && field.itemId === 92) {
        this.currentUser = undefined;

        window.location.href = "/login?customUrl=interim";
      }
    }

    Http.clearTokens();

    this.currentUser = undefined;
    if (this.callback && typeof this.callback === "function") {
      this.callback(undefined);
    }
  }

  async refreshUser() {
    try {
      const { data: user } = await Http.get("/user");
      this.currentUser = user;
    } catch (error) {}
  }
}

const authService = new AuthService();

export default authService;

function delete_cookie(name, path, domain) {
  if (get_cookie(name)) {
    document.cookie =
      name +
      "=" +
      (path ? ";path=" + path : "") +
      (domain ? ";domain=" + domain : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}
function get_cookie(name) {
  return document.cookie.split(";").some((c) => {
    return c.trim().startsWith(name + "=");
  });
}
