import React from "react";

import authService from "@lib/auth/authService";
import clientStore from "@features/client/models/store";

import ModalDoubleAuth from "./modal";
import Profile from "./profile";
import Logo from "./logo";

export default function AppHeader() {
  const currentUser = authService.currentUser;
  return (
    <header className="app-header">
      <div>
        <Logo />
      </div>
      <div>
        <Profile />
      </div>
      {currentUser &&
        currentUser.role !== "ADMIN" &&
        clientStore.client.preferences.withDoubleAuth &&
        ((clientStore.client.preferences.withExtrafields &&
          currentUser.userExtrafields.length < 1) ||
          (clientStore.client.preferences.withLists &&
            currentUser &&
            currentUser.lists.length < 1)) && <ModalDoubleAuth />}
    </header>
  );
}
