import React from "react";

import { mount, lazy, redirect, route, map, compose, withView } from "navi";

import BaseLayout from "@components/layouts/base";
import ELearningLayout from "@components/layouts/e-learning";
import { isTokenValid } from "@features/login/lib";

const ResetPasswordPage = React.lazy(() =>
  import(
    /* webpackChunkName: "reset-password" */ "./features/login/resetPassword"
  )
);
const LoginPage = React.lazy(() =>
  import(/* webpackChunkName: "login" */ "./features/login")
);

export const routes = mount({
  "*": map((request, context) =>
    !context.currentUser
      ? redirect("/login", { exact: false })
      : compose(
          withView((request, context) => layoutBalancer(context)),
          mount({
            "/e-learning": lazy(() =>
              import(
                /* webpackChunkName: "elearning" */ "./features/elearning/routes"
              )
            ),
            "/users": lazy(() =>
              import(/* webpackChunkName: "users" */ "./features/users/routes")
            ),

            "/settings": lazy(() =>
              import(
                /* webpackChunkName: "settings" */ "./features/settings/routes"
              )
            ),

            "/courses": lazy(() =>
              import(
                /* webpackChunkName: "courses" */ "./features/courses/routes"
              )
            ),

            "/reporting": lazy(() =>
              import(
                /* webpackChunkName: "reporting" */ "./features/reporting/routes"
              )
            ),
          })
        )
  ),
  "/": map((request, context) => roleBalancer(context)),
  "/legal": route({
    title: "Conditions générales - 2J MOBILE",
    getView: () =>
      import(/* webpackChunkName: "legal" */ "./components/legalPage"),
  }),
  "/reset-password/:userId/:token": route({
    title: "Réinitialiser votre mot de passe - 2J MOBILE",
    getView: async (req) => (
      <ResetPasswordPage
        userId={req.params.userId}
        token={req.params.token}
        isTokenValid={await isTokenValid(req.params.userId, req.params.token)}
      />
    ),
  }),
  "/login": map(async (request, context) => {
    return context.currentUser
      ? redirect("/")
      : route({
          title: "Connexion - 2J MOBILE",
          getView: () => (
            <LoginPage
              forgotPassword={
                request.params.forgotPassword
                  ? decodeURIComponent(request.params.forgotPassword)
                  : false
              }
              adminOnly={request.params.admin}
              customUrl={request.params.customUrl}
            />
          ),
        });
  }),
});

function roleBalancer(context) {
  if (context.currentUser) {
    switch (context.currentUser.role) {
      case "TUTOR":
      case "ADMIN":
        return redirect("/users");

      case "LEARNER":
        return redirect("/e-learning");
      default:
        break;
    }
  }

  return redirect("/login");
}

function layoutBalancer(context) {
  if (context.currentUser) {
    switch (context.currentUser.role) {
      case "TUTOR":
      case "ADMIN":
        return <BaseLayout />;

      case "LEARNER":
        return <ELearningLayout />;
      default:
        break;
    }
  }

  return redirect("/login");
}
