import Http from "@lib/http";

export async function isTokenValid(userId, token) {
  try {
    await Http.get(
      `/user/check-reset-password-token/${encodeURIComponent(
        userId
      )}/${encodeURIComponent(token)}`
    );
    return true;
  } catch (error) {
    return false;
  }
}
