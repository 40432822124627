/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useCurrentRoute } from "react-navi";
import { Menu, Icon } from "antd";

import { useMediaQuery } from "@lib/hooks";
import authService from "@lib/auth/authService";
import { ADMIN } from "@features/users/constants";

import clientStore, { defaultAlt } from "../../features/client/models/store";

export const defaultSrc = require("../../styles/images/logo/logo-small.png");

const mapping = [
  [/settings\/extrafields/, ["settings", "extrafields"]],
  [/settings\/lists/, ["settings", "lists"]],
  [/settings\/notifications/, ["settings", "notifications"]],
  [/users\/learners/, ["users", "learners"]],
  [/users\/learner\//, ["users", "learners"]],
  [/users\/tutors/, ["users", "tutors"]],
  [/courses/, [null, "courses"]],
  [/reporting/, [null, "reporting"]]
];

function match(url) {
  let match;
  for (const [regex, pair] of mapping) {
    if (url.match(regex) !== null) {
      match = pair;
      break;
    }
  }

  return match;
}

export default function AppMenu() {
  const isTablet = useMediaQuery("(max-width: 48rem)");
  const route = useCurrentRoute();
  const pair = match(route.url.pathname);

  const currentUserRole = authService.currentUser?.role;

  const [openKeys, setOpenKeys] = useState(() =>
    pair && !isTablet ? [pair[0]] : []
  );
  const [selectedKeys, setSelectedKeys] = useState(() =>
    pair ? [pair[1]] : []
  );

  useEffect(() => {
    if (pair) {
      if (!isTablet && pair[0] && !openKeys.includes(pair[0])) {
        setOpenKeys(openKeys.concat([pair[0]]));
      }

      if (pair[1] && !selectedKeys.includes(pair[1])) {
        setSelectedKeys([pair[1]]);
      }
    } else {
      setSelectedKeys([]);
    }
  }, [route.url.pathname]);

  useEffect(() => {
    if (isTablet) {
      setOpenKeys([]);
    } else if (pair && pair[0]) {
      setOpenKeys([pair[0]]);
    }
  }, [isTablet]);

  const client = clientStore.client;

  return (
    <div className="app-menu-wrapper">
      <div className="app-menu">
        <Menu
          mode="inline"
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          onClick={v => setSelectedKeys([v.key])}
          onOpenChange={setOpenKeys}
          inlineCollapsed={isTablet}
        >
          {currentUserRole === ADMIN ? (
            <Menu.SubMenu
              key="users"
              title={
                <span>
                  <Icon type="team" />
                  <span>Gestion des comptes</span>
                </span>
              }
            >
              <Menu.Item key="learners">
                <Link href="/users/learners">Apprenants</Link>
              </Menu.Item>
              <Menu.Item key="tutors">
                <Link href="/users/tutors">Tuteurs</Link>
              </Menu.Item>
            </Menu.SubMenu>
          ) : (
            <Menu.Item key="learners">
              <Link href="/users/learners">
                <Icon type="team" />
                <span>Apprenants</span>
              </Link>
            </Menu.Item>
          )}
          {currentUserRole === ADMIN && (
            <Menu.Item key="courses">
              <Link href="/courses">
                <Icon type="read" />
                <span>Parcours</span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="reporting">
            <Link href="/reporting">
              <Icon type="bar-chart" />
              <span>Analyse</span>
            </Link>
          </Menu.Item>
          {currentUserRole === ADMIN && (
            <Menu.SubMenu
              key="settings"
              title={
                <span>
                  <Icon type="setting" />
                  <span>Paramètres</span>
                </span>
              }
            >
              {client.preferences.withExtrafields && (
                <Menu.Item key="extrafields">
                  <Link href="/settings/extrafields">
                    Champs supplémentaires
                  </Link>
                </Menu.Item>
              )}
              {client.preferences.withLists && (
                <Menu.Item key="lists">
                  <Link href="/settings/lists">Arborescence</Link>
                </Menu.Item>
              )}
              <Menu.Item key="notifications">
                <Link href="/settings/notifications">Notifications</Link>
              </Menu.Item>
            </Menu.SubMenu>
          )}
        </Menu>
        {!client.isDefaultLogo && (
          <div className="ant-menu-inline">
            <img src={defaultSrc} alt={defaultAlt} className="logo-signature" />
          </div>
        )}
      </div>
    </div>
  );
}
