import { types, flow } from "mobx-state-tree";

import Http from "@lib/http";
import { defaultConfig } from "@lib/http/config";

export const defaultSrc = require("../../../styles/images/logo/logo.png");
export const defaultAlt = "Logo 2J MOBILE";

const ClientPreferences = types.model("ClientPreferences", {
  authMode: types.optional(
    types.enumeration(["EMAIL_CODE", "USERNAME_PASSWORD", "CUSTOM"]),
    "USERNAME_PASSWORD"
  ),
  withElearningAccess: false,
  withExtrafields: false,
  withLists: false,
  withDoubleAuth: false,
  customLoginURL: types.maybeNull(types.string),
});

const ClientBadges = types.model("ClientBadges", {
  id: types.number,
  name: types.string,
  description: types.string,
  identifier: types.string,
});

const Client = types
  .model("Client", {
    id: types.number,
    name: types.string,
    identifier: types.string,
    enabled: types.boolean,
    badges: types.array(ClientBadges),
    badgesFetched: false,
    preferences: types.optional(ClientPreferences, {}),
    logoSrc: types.optional(types.string, defaultSrc),
    logoAlt: types.optional(types.string, defaultAlt),
  })
  .views((self) => ({
    get isEmailRequired() {
      if (self.preferences.authMode === "EMAIL_CODE") {
        return true;
      }
      return false;
    },
    get isDefaultLogo() {
      return self.logoAlt === defaultAlt;
    },
  }))
  .actions((self) => ({
    getBadges: flow(function* getBadges() {
      if (self.badgesFetched) return;

      try {
        const response = yield Http.get(`/client/${self.identifier}/badges`);

        if (response) {
          self.badges = response.data;
          self.badgesFetched = true;
        }
      } catch (error) {
        console.log("Impossible de récupérer les badges");
      }
    }),
  }));

const ClientStore = types
  .model("ClientStore", {
    client: types.maybe(Client),
    displaySelector: false,
  })
  .views((self) => ({
    get isForbidden() {
      return self.client && !self.client.enabled;
    },
  }))
  .actions((self) => ({
    getClient: flow(function* getClient(identifier) {
      try {
        const response = yield Http.get(`/client/${identifier}`);

        let logoSrc, logoAlt;
        try {
          yield Http.get(
            `${defaultConfig.baseURL}/client/${response.data.identifier}/logo`
          );
          logoSrc = `${defaultConfig.baseURL}/client/${response.data.identifier}/logo`;
          logoAlt = `Logo ${response.data.name}`;
        } catch (error) {
          console.log(
            "Impossible de charger le logo du client. Logo par défaut utilisé."
          );
        }

        const client = Client.create({
          ...response.data,
          logoSrc,
          logoAlt,
          preferences: {
            authMode: response.data.settingAuthMode,
            withElearningAccess: response.data.settingWithElearningAccess,
            withExtrafields: response.data.settingWithExtrafields,
            withLists: response.data.settingWithList,
            withDoubleAuth: response.data.settingWithDoubleAuth,
            customLoginURL: response.data.settingCustomLoginURL,
          },
        });

        self.client = client;
        window.client = client;
      } catch (error) {
        console.log(error);
        self.displaySelector = true;
      }
    }),
  }));

export default ClientStore.create();
