import React, { useState, useEffect } from "react";

export default function LoadingBar() {
  const [showLoader, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 1 * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!showLoader) return null;

  return (
    <div className="loading-bar">
      <div className="loading-bar-progress"></div>
    </div>
  );
}
